<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue';

const props = defineProps({
  avatarId: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  bordered: {
    type: Boolean,
    default: false
  }
});

const isMounted = ref(false);
const avatarMissing = ref(false);

const initials = computed(() => {
  const names = props.name.split(' ');
  const firstLetter = names.at(0)?.at(0) ?? '';
  const secondLetter = names.at(1)?.at(0) ?? '';

  return `${firstLetter.toUpperCase()}${secondLetter.toUpperCase()}`;
});

onMounted(() => (isMounted.value = true));
watch(() => props.avatarId, () => avatarMissing.value = false);
</script>

<template>
  <div
    v-if="isMounted"
    class="h-full w-full"
  >
    <img
      v-if="props.avatarId && !avatarMissing"
      class="rounded-full w-full h-full object-cover bg-black"
      :class="{
        'border-2 border-white': props.bordered
      }"
      :src="`/avatar/${props.avatarId}`"
      :onError="() => (avatarMissing = true)"
    >
    <div
      v-else
      class="bg-[#FE6233] rounded-full h-full w-full flex"
      :class="{
        'border-2 border-white': props.bordered
      }"
    >
      <span
        class="m-auto font-bold"
        :title="props.name"
      >
        {{ initials }}
      </span>
    </div>
  </div>
</template>
